import React from 'react';
// import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './Home'
import OverFlightPrivacy from './Privacy/OverFlight'
import PilotBuddyPrivacy from './Privacy/PilotBuddy'
import ReverseTimePrivacy from './Privacy/ReverseTime'

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/privacy/overflight">
            <OverFlightPrivacy />
          </Route>
          <Route path="/privacy/pilot-buddy">
            <PilotBuddyPrivacy />
          </Route>
          <Route path="/privacy/reverse-time">
            <ReverseTimePrivacy />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <footer className="App-footer">
          <p>&copy; Alex House IT Ltd 2020.</p>
          <p>Alex House IT Ltd is registered in England under company number 12383497. Our registered office is: First Floor, Telecom House 125-135 Preston Road, Brighton, East Sussex, BN1 6AF.</p>
          <p><Link to="/privacy/overflight">Privacy Policy</Link></p>
        </footer>
      </Router>
    </div>
  );
}

export default App;
