import React from 'react';
// import logo from './logo.svg';
import './App.css';

function Home() {
  return (
    <header className="App-header">
      <h1>
        {/*<img src="logo512.jpg" className="App-logo" alt="Alex House IT Ltd" />*/}
        Alex House IT Ltd
      </h1>
      <p>software development &amp; infrastructure support</p>
    </header>
  );
}

export default Home;
